<template>
    <v-avatar :size=size tile>
        <v-img v-if="useCustomImage" :src="iconData" aspect-ratio="1"></v-img>
        <font-awesome-icon v-if="!useCustomImage" :icon="defaultIcon" fixed-width size="1x"></font-awesome-icon>
    </v-avatar>
</template>

<script>
export default {

    props: {
        /**
         * Base64 icon data
         */
        realmImage: {
            type: String,
            default: null,
        },
        /**
         * Base64 account data
         */
        accountImage: {
            type: String,
            default: null,
        },
        size: {
            type: Number,
            default: 64,
        },
    },

    computed: {
        iconData() {
            if (this.realmImage) {
                return `data:image/png;base64,${this.realmImage}`;
            }
            if (this.accountImage) {
                return `data:image/png;base64,${this.accountImage}`;
            }
            return '';
        },
        useCustomImage() {
            if (this.realmImage || this.accountImage) {
                return true;
            }
            return false;
        },
        defaultIcon() {
            return ['fas', 'lock'];
        },
    },

};
</script>
