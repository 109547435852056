<template>
    <AppLayout>
        <RequireEnterpriseAccount>
        <Snackbar :attr="snackbar"/>
            <v-snackbar v-model="snackbarAuthorizationTokenCreated" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Authorization token created</span>
                <v-btn text class="blue white--text" @click="snackbarAuthorizationTokenCreated = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarAuthorizationTokenEdited" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Authorization token edited</span>
                <v-btn text class="blue white--text" @click="snackbarAuthorizationTokenEdited = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarAuthorizationTokenDeleted" :timeout="2000" top color="red" class="mt-0 pt-0">
                <span>Authorization token deleted</span>
                <v-btn text class="red white--text" @click="snackbarAuthorizationTokenDeleted = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarAuthorizationTokenCopied" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Authorization token copied</span>
                <v-btn text class="blue white--text" @click="snackbarAuthorizationTokenCopied = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarRealmCreated" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Authentication realm created</span>
                <v-btn text class="blue white--text" @click="snackbarRealmCreated = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarRealmEdited" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Authentication realm edited</span>
                <v-btn text class="blue white--text" @click="snackbarRealmEdited = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarRealmDeleted" :timeout="2000" top color="red" class="mt-0 pt-0">
                <span>Authentication realm deleted</span>
                <v-btn text class="red white--text" @click="snackbarRealmDeleted = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarRealmIdCopied" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Authorization realm ID copied</span>
                <v-btn text class="blue white--text" @click="snackbarRealmIdCopied = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
              <v-snackbar v-model="snackbarRealmImageUpdated" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Realm logo updated</span>
                <v-btn text class="blue white--text" @click="snackbarRealmImageUpdated = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarRealmImageUpdateFailed" :timeout="2000" top color="red" class="mt-0 pt-0">
                <span>Failed to update realm logo</span>
                <v-btn text class="red white--text" @click="snackbarRealmImageUpdateFailed = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
        <Tutorial next="/account">
            <p class="text-subtitle-1 font-weight-light text-center">The <b>dashboard</b> is where you can see what's new and see an overview of the activity in the Enterprise account. You can return to this page at any time by clicking the LoginShield logo on the top-left corner of the window, or by selecting an account from the menu on the top-right corner of the window.</p>
        </Tutorial>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ displayName }}</h1>
            </v-col>
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p><router-link :to="{ path: '/account', query: { acct: $route.query.acct } }">Account settings</router-link></p>
            </v-col>
        </v-row>
        <!-- NOTE: see same card in DashboardFree and DashboardPersonal -->
        <!-- <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card elevation="6" class="px-5 pb-5">
                    <v-card-text class="text-h6 text-center">Tutorial</v-card-text>
                    <v-divider class="mx-5 mb-5"></v-divider>
                    <v-row justify="center" class="py-5 px-5">
                        <p class="text-body-1 font-weight-light text-center">
                            Enable the tutorial to get helpful information for any page.
                            Look for the <b>Tutorial Mode</b> switch in the blue help bar
                            at the bottom of each page.
                        </p>
                    </v-row>
                </v-card>
            </v-col>
        </v-row> -->
        <v-row justify="center" class="py-5" v-show="currentAccount && currentAccount.type === ACCOUNT_TYPE_ENTERPRISE">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-overline mb-0">Authentication realms <span>({{ realmList.length }})</span></p>
                <!-- <p>
                    <Avatar :attr="currentAccount" :size="36"/>
                    <v-btn class="blue--text no-print" @click="dialogEditAccountImage = true" icon>
                        <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                    </v-btn>
                </p> -->
                <v-list class="ma-0 pa-0" dense>
                    <v-list-item v-for="realm in realmList" v-bind:key="realm.id" @click="openDialogRealm(realm.id)" class="ma-0 py-1 px-4">
                        <v-list-item-icon>
                            <AvatarRealm :realmImage="realm.icon" :accountImage="currentAccount.icon" :size="24" />
                        </v-list-item-icon>
                        <!-- <v-img contain :src="getRealmIcon(realm.icon)" v-if="realm.icon" max-width="34" max-height="34" class="mr-5"/> -->
                        <v-list-item-content> <!--  style="font-size: 14px!important" -->
                            <v-list-item-title> <!--  class="text-overline" -->
                                {{ realm.label }}
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 0.7em;">
                                {{ realm.domain }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-spacer></v-spacer>
                    </v-list-item>
                </v-list>
                <p class="mt-1"><router-link to="/enterprise/add-realm">Add realm</router-link></p>
                <!-- <v-row justify="center" v-show="realmList.length === 0" class="mt-5">
                    <p class="text-body-1 font-weight-light">Create your first authentication realm</p>
                </v-row>
                <v-row justify="center" class="pt-4 pb-2">
                    <v-btn outlined color="blue white--text" elevation="4" @click="openDialogNewRealm()">New Realm</v-btn>
                </v-row> -->
                <v-dialog v-model="dialogRealm" max-width="600">
                    <v-card elevation="12" class="px-5 pb-5">
                        <v-card-title>
                            <v-row justify="center">
                                <v-img contain :src="getRealmIcon(editableRealm.icon)" v-if="editableRealm.icon" max-width="30" max-height="30" style="position: absolute; left: 0;" class="ml-7"/>
                                <span class="text-h5">Authentication Realm</span>
                                <span class="pr-5" style="position: absolute; right: 0;">
                                    <v-btn icon color="grey" href="#" @click="dialogRealm = false">
                                        <font-awesome-icon icon="times" fixed-width style="font-size: 16px;"/>
                                    </v-btn>
                                </span>
                            </v-row>
                        </v-card-title>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="py-0 px-8">
                            <v-text-field
                                    v-model="editableRealm.id"
                                    label="Realm ID"
                                    readonly
                                    outlined
                                    ref="realmIdRef"
                                ></v-text-field>
                            <v-spacer></v-spacer>
                            <v-btn outlined color="blue" elevation="4" @click="copyRealmId">Copy</v-btn>
                            </v-row>
                        <v-form v-model="formRealmLabel" ref="formRealmLabelRef" @submit="validateFormEditRealmLabel" onSubmit="return false;" @keyup.enter.native="validateFormEditRealmLabel">
                            <v-row class="px-8">
                                <v-text-field
                                    v-model="editableRealm.label"
                                    label="Realm Name"
                                    :rules="realmLabelRules"
                                    outlined
                                    ref="realmLabelRef">
                                </v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn outlined color="blue" :disabled="!formRealmLabel || !isRealmLabelEditEnabled" elevation="4" @click="editRealmLabel">Rename</v-btn>
                            </v-row>
                        </v-form>
                        <v-form v-model="formRealmDomain" ref="formRealmDomainRef" @submit="validateFormEditRealmDomain" onSubmit="return false;" @keyup.enter.native="validateFormEditRealmDomain">
                            <v-row justify="center" class="px-8">
                                <v-text-field
                                    v-model="editableRealm.domain"
                                    label="Domain Name"
                                    :rules="realmDomainRules"
                                    outlined
                                ></v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn outlined color="blue" :disabled="!formRealmDomain || !isRealmDomainEditEnabled" elevation="4" @click="editRealmDomain">Rename</v-btn>
                            </v-row>
                        </v-form>
                        <v-form v-model="formRealmAndroidApplicationId" ref="formRealmAndroidApplicationIdRef" @submit="validateFormEditRealmAndroidApplicationId" onSubmit="return false;" @keyup.enter.native="validateFormEditRealmAndroidApplicationId">
                            <v-row justify="center" class="px-8">
                                <v-text-field
                                        v-model="editableRealm.androidApplicationId"
                                        label="Android Application ID"
                                        :rules="androidApplicationIdRules"
                                        outlined
                                    ></v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn outlined color="blue" :disabled="!formRealmAndroidApplicationId || !isAndroidApplicationIdEditEnabled" elevation="4" @click="editAndroidApplicationId">Save</v-btn>
                            </v-row>
                            </v-form>
                        <v-form v-model="formRealmImage" ref="formRealmImageRef" @submit="validateFormEditRealmImage" onSubmit="return false;" @keyup.enter.native="validateFormEditRealmImage">
                            <v-row justify="center" class="px-8">
                                    <v-file-input
                                    v-model="realmImage"
                                    show-size
                                    accept="image/png"
                                    :rules="avatarRules"
                                    label="Choose realm logo (.png)"
                                    outlined
                                    prepend-icon=""
                                    class="pr-10">
                                </v-file-input>
                                <v-btn outlined color="blue" :disabled="!formRealmImage" elevation="4" @click="editRealmImage">Save</v-btn>
                            </v-row>
                        </v-form>
                        <v-row justify="center" class="pt-2">
                            <v-btn outlined color="red" elevation="4" @click="openDialogDeleteRealm()">Delete</v-btn>
                        </v-row>
                        <v-row justify="center" class="pt-5" v-show="errorDeletingRealm">
                            <p class="text-body-1 font-weight-light red--text">Could not delete authentication realm</p>
                        </v-row>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDeleteRealm" max-width="400">
                    <v-card elevation="24" class="px-5 pb-2" style="border: solid 1px red;">
                        <v-card-title>
                            <v-row justify="center">
                                <span class="text-h5">Delete Realm</span>
                                <span class="pr-5" style="position: absolute; right: 0;">
                                    <v-btn icon color="grey" href="#" @click="dialogDeleteRealm = false;">
                                        <font-awesome-icon icon="times" fixed-width style="font-size: 16px;"/>
                                    </v-btn>
                                </span>
                            </v-row>
                        </v-card-title>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="px-4 pt-1">
                            <p class="text-h6 font-weight=light">{{this.editableRealm.label}}</p>
                        </v-row>
                        <v-row justify="center" class="px-4 pb-2">
                            <p class="text-h6 font-weight-light">{{this.editableRealm.domain}}</p>
                        </v-row>
                        <v-row justify="center" class="px-4">
                            <p class="text-body-1 font-weight-light red--text">This action is irreversible</p>
                        </v-row>
                        <v-row justify="center" class="py-2">
                            <v-btn color="red white--text" elevation="4" @click="deleteRealm" min-width="80px">Delete</v-btn>
                        </v-row>
                        <v-row justify="center" v-show="errorDeletingRealm" class="pt-4 pb-2">
                            <p class="text-body-1 font-weight-light red--text">Could not delete authentication realm</p>
                        </v-row>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogNewRealm" max-width="600">
                    <v-card elevation="12" class="px-5 pb-2">
                        <v-form v-model="formNewRealm" ref="formNewRealmRef" @submit="validateformNewRealm" onSubmit="return false;" @keyup.enter.native="validateformNewRealm">
                            <v-card-title>
                                <v-row justify="center">
                                    <span class="text-h5">New Authentication Realm</span>
                                    <span class="pr-5" style="position: absolute; right: 0;">
                                    <v-btn icon color="grey" href="#" @click="dialogNewRealm = false">
                                        <font-awesome-icon icon="times" fixed-width style="font-size: 16px;"/>
                                    </v-btn>
                                </span>
                                </v-row>
                            </v-card-title>
                            <v-divider class="mx-5 mb-5"></v-divider>
                            <v-row justify="center" class="px-8">
                                <v-text-field
                                    v-model = "editableRealm.label"
                                    label="Realm Name"
                                    :rules="newRealmLabelRules"
                                    outlined
                                    ref="newRealmLabelRef">
                                </v-text-field>
                            </v-row>
                            <v-row justify="center" class="px-8">
                                <v-text-field
                                    v-model= "editableRealm.domain"
                                    label="Domain Name"
                                    :rules="newRealmDomainRules"
                                    outlined
                                    ref="newRealmDomainRef"
                                ></v-text-field>
                            </v-row>
                            <v-row justify="center" class="pb-3">
                                <v-btn color="blue white--text" elevation="2" :disabled="!formNewRealm" @click="createRealm" min-width="100px">Create</v-btn>
                            </v-row>
                                <!-- <v-btn outlined color="blue" elevation="2" @click="createRealm" min-width="100px">Create</v-btn> -->
                            <v-row justify="center" v-show="errorCreateRealm">
                                <p class="text-body-1 font-weight-light red--text">Could not create authentication realm</p>
                            </v-row>
                        </v-form>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>

        <!-- <v-row justify="center" class="py-5" v-show="currentAccount && currentAccount.type === ACCOUNT_TYPE_ENTERPRISE">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-overline mb-0">Authorization tokens <span>({{ authorizationTokenList.length }})</span></p>
                <v-list class="ma-0 pa-0">
                    <v-list-item v-for="token in authorizationTokenList" v-bind:key="token.id" @click="openDialogAuthorizationToken(token.id)" class="ma-0 py-1 px-4">
                        <v-list-item-content class="text-overline" style="font-size: 14px!important">
                            {{token.label}}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-row justify="center" v-show="authorizationTokenList.length === 0" class="mt-5">
                    <p class="text-body-1 font-weight-light">Create your first authorization token</p>
                </v-row>
                <v-row justify="center" class="py-2">
                    <v-btn outlined color="blue white--text" elevation="4" @click="openDialogNewAuthorizationToken()">New Token</v-btn>
                </v-row>
                <v-dialog v-model="dialogAuthorizationToken" max-width="600">
                    <v-card elevation="12" class="px-5 pb-5">
                        <v-form v-model="formAuthorizationToken" ref="formAuthorizationTokenRef" @submit="validateFormEditAuthorizationToken" onSubmit="return false;" @keyup.enter.native="validateFormEditAuthorizationToken">
                            <v-card-title>
                                <v-row justify="center">
                                    <span class="text-h5">Authorization Token</span>
                                    <span class="pr-5" style="position: absolute; right: 0;">
                                    <v-btn icon color="grey" href="#" @click="dialogAuthorizationToken = false">
                                        <font-awesome-icon icon="times" fixed-width style="font-size: 16px;"/>
                                    </v-btn>
                                </span>
                                </v-row>
                            </v-card-title>
                            <v-divider class="mx-5 mb-5"></v-divider>
                            <v-row class="px-8">
                                <v-text-field
                                    v-model = "editableAuthorizationToken.label"
                                    label="Token Label"
                                    :rules="authorizationTokenLabelRules"
                                    outlined
                                    ref="authorizationTokenLabelRef">
                                </v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn outlined color="blue" :disabled="!formAuthorizationToken || !isAuthorizationTokenEditEnabled" @click="editAuthorizationToken">Rename</v-btn>
                            </v-row>
                            <v-row justify="center">
                                <v-btn color="red white--text" elevation="4" @click="deleteAuthorizationToken">Delete</v-btn>
                            </v-row>
                            <v-row justify="center" class="pt-5" v-show="errorDeletingAuthorizationToken">
                                <p class="text-body-1 font-weight-light red--text">Could not delete authorization token</p>
                            </v-row>
                        </v-form>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogNewAuthorizationToken" max-width="600">
                    <v-card elevation="12" class="px-5 pb-2">
                        <v-form v-model="formNewAuthorizationToken" ref="formNewAuthorizationTokenRef" @submit="validateformNewAuthorizationToken" onSubmit="return false;" @keyup.enter.native="validateformNewAuthorizationToken">
                            <v-card-title>
                                <v-row justify="center">
                                    <span class="text-h5">New Authorization Token</span>
                                    <span class="pr-5" style="position: absolute; right: 0;">
                                    <v-btn icon color="grey" href="#" @click="dialogNewAuthorizationToken = false">
                                        <font-awesome-icon icon="times" fixed-width style="font-size: 16px;"/>
                                    </v-btn>
                                </span>
                                </v-row>
                            </v-card-title>
                            <v-divider class="mx-5 mb-5"></v-divider>
                            <v-row class="px-8">
                                <v-text-field
                                    v-model = "editableAuthorizationToken.label"
                                    label="Label"
                                    :rules="newAuthorizationTokenLabelRules"
                                    v-show="!authorizationTokenCreated"
                                    outlined
                                    ref="editableAuthorizationTokenLabelRef">
                                </v-text-field>
                                <v-text-field
                                    v-model = "editableAuthorizationToken.label"
                                    label="Label"
                                    v-show="authorizationTokenCreated"
                                    outlined
                                    readonly>
                                </v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn color="blue white--text" elevation="2" :disabled="!formNewAuthorizationToken" @click="createAuthorizationToken" min-width="100px" v-show="!authorizationTokenCreated">Create</v-btn>
                            </v-row>
                            <v-row justify="center" v-show="errorCreateAuthorizationToken">
                                <p class="text-body-1 font-weight-light red--text">Could not create authorization token</p>
                            </v-row>
                            <v-row justify="center" v-show="errorEditAuthorizationToken">
                                <p class="text-body-1 font-weight-light red--text">Could not rename authorization token</p>
                            </v-row>
                            <v-row justify="center" class="pt-0 px-8" v-show="showAuthorizationToken">
                                <v-textarea
                                    v-model = "authorizationToken"
                                    label="Token"
                                    outlined
                                    readonly
                                    auto-grow
                                    ref="tokenValueRef">
                                </v-textarea>
                            </v-row>
                            <v-row justify="center" v-show="showAuthorizationToken">
                                <p class="font-weight-light text-body-1">This is the only time the token will be shown</p>
                            </v-row>
                            <v-row justify="center" class="pb-5" v-show="showAuthorizationToken">
                                <v-btn class="blue white--text" elevation="2" @click="copyAuthorizationToken" min-width="100px">Copy</v-btn>
                            </v-row>
                        </v-form>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row> -->

        </RequireEnterpriseAccount>
    </AppLayout>
</template>
<style lang="css">
.v-list-item__icon {
    justify-content: center;
    align-content: center;
}
@media print {
  /* add margin around the main content to separate it from the page header and footer */
  @page {
    margin-top: 2cm;
    margin-bottom: 2cm;
  }
  /* removes padding corresponding to navbar and footer when printing document, adds one line of padding to top of document to separate page title from page content */
  .print {
    padding: 0 !important;
  }
  /* hides elements that should not appear in print */
  .no-print {
      display: none !important;
  }

}
</style>
<script>
import { mapState, mapGetters } from 'vuex';
import RequireEnterpriseAccount from '@/components/RequireEnterpriseAccount.vue';
import Tutorial from '@/components/Tutorial.vue';
import AppLayout from '@/components/AppLayout.vue';
import Snackbar from '@/components/Snackbar.vue';
import AvatarRealm from '@/components/AvatarRealm.vue';
// import Avatar from '@/components/Avatar.vue';
// import TextLink from '@/components/TextLink.vue';
import loginshield from '@/client';
import { compact } from '@/sdk/input';
import { ACCOUNT_TYPE_ENTERPRISE, ACCOUNT_TYPE_PERSONAL, ACCOUNT_TYPE_FREE } from '@/constants';

export default {
    components: {
        RequireEnterpriseAccount,
        Tutorial,
        AppLayout,
        Snackbar,
        AvatarRealm,
    },
    data() {
        return {
            snackbar: {},
            snackbarRealmImageUpdated: false,
            snackbarRealmImageUpdateFailed: false,
            snackbarAuthorizationTokenCreated: false,
            snackbarAuthorizationTokenEdited: false,
            snackbarAuthorizationTokenDeleted: false,
            snackbarAuthorizationTokenCopied: false,
            snackbarRealmIdCopied: false,
            snackbarRealmCreated: false,
            snackbarRealmEdited: false,
            snackbarRealmDeleted: false,
            snackbarDomainUpdated: false,
            formDomain: null,
            formAuthorizationToken: false,
            formRealmLabel: false,
            formRealmDomain: false,
            formNewAuthorizationToken: false,
            formRealmAndroidApplicationId: false,
            formRealmImage: false,
            formNewRealm: false,
            editableName: null,
            editableDomain: null,
            dialogAuthorizationToken: false,
            dialogRealm: false,
            dialogNewAuthorizationToken: false,
            dialogNewRealm: false,
            dialogDeleteRealm: false,
            editableAuthorizationToken: { id: null, label: null },
            editableRealm: {
                id: null, label: null, domain: null, androidApplicationId: null,
            },
            showAuthorizationToken: false,
            authorizationTokenCreated: false,
            authorizationToken: null,
            errorCreateAuthorizationToken: false,
            errorEditAuthorizationToken: false,
            errorCreateRealm: false,
            errorDeletingAuthorizationToken: false,
            errorDeletingRealm: false,
            realmImage: [],
            avatarRules: [
                (v) => (v && v.size < 400 * 1024) || 'File size should be less than 400 KB!',
            ],
            authorizationTokenLabelRules: [
                (v) => (v && compact(v).length > 0) || 'Enter Authorization Token Label',
                (v) => !v || !this.authorizationTokenMap[this.editableAuthorizationToken.id] || v === this.authorizationTokenMap[this.editableAuthorizationToken.id].label || !this.compactAuthorizationTokenLabels.includes(compact(v)) || 'You already have a token with a similar name',
            ],
            newAuthorizationTokenLabelRules: [
                (v) => (v && compact(v).length > 0) || 'Enter Authorization Token Label',
                (v) => !v || !this.compactAuthorizationTokenLabels.includes(compact(v)) || 'You already have a token with a similar name',
            ],
            realmLabelRules: [
                (v) => (v && compact(v).length > 0) || 'Enter Realm Name',
                (v) => !v || !this.realmMap[this.editableRealm.id] || v === this.realmMap[this.editableRealm.id].label || !this.compactRealmLabels.includes(compact(v)) || 'You already have a realm with a similar name',
            ],
            realmDomainRules: [
                (v) => (v && compact(v).length > 0) || 'Enter Domain Name',
                (v) => !v || !this.realmMap[this.editableRealm.id] || v === this.realmMap[this.editableRealm.id].domain || !this.compactRealmDomains.includes(compact(v)) || 'You already have a domain with a similar name',
            ],
            newRealmLabelRules: [
                (v) => (v && compact(v).length > 0) || 'Enter Domain Name',
                (v) => !v || !this.compactRealmLabels.includes(compact(v)) || 'You already have a label with a similar name',
            ],
            newRealmDomainRules: [
                (v) => (v && compact(v).length > 0) || 'Enter Domain Name',
                (v) => !v || !this.compactRealmDomains.includes(compact(v)) || 'You already have a domain with a similar name',
            ],
            androidApplicationIdRules: [
                (v) => (v && compact(v).length > 0) || 'Enter Android Application ID',
                (v) => !v || !this.realmMap[this.editableRealm.id] || v === this.realmMap[this.editableRealm.id].androidApplicationId || !this.compactRealmAndroidApplicationIds.includes(compact(v)) || 'You already have an Android application id with a similar name',
            ],
            authorizationTokenMap: {},
            realmMap: {},
        };
    },
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
            // user: (state) => state.user,
            // accountMap: (state) => state.accountMap,
            // currentAccountId: (state) => state.accountId,
        }),
        ...mapGetters({
            currentAccount: 'account', // currently selected account object
        }),
        displayName() {
            return this.currentAccount ? this.currentAccount.name : 'Enterprise Dashboard';
        },
        isPermitEdit() {
            if (this.currentAccount) {
                const permit = this.currentAccount.permit || [];
                return permit.includes('edit');
            }
            return false;
        },
        isAuthorizationTokenEditEnabled() {
            console.log('this.editableAuthorizationToken: ', this.editableAuthorizationToken);
            console.log('this.authorizationTokenMap: ', this.authorizationTokenMap[this.editableAuthorizationToken.id]);
            console.log('this.editableAuthorizationToken.label: ', this.editableAuthorizationToken.label);
            return this.editableAuthorizationToken
            && this.authorizationTokenMap[this.editableAuthorizationToken.id]
            && this.editableAuthorizationToken.label !== this.authorizationTokenMap[this.editableAuthorizationToken.id].label;
        },
        isRealmDomainEditEnabled() {
            return this.editableRealm
            && this.realmMap[this.editableRealm.id]
            && this.editableRealm.domain !== this.realmMap[this.editableRealm.id].domain;
        },
        isRealmLabelEditEnabled() {
            return this.editableRealm
            && this.realmMap[this.editableRealm.id]
            && this.editableRealm.label !== this.realmMap[this.editableRealm.id].label;
        },
        isAndroidApplicationIdEditEnabled() {
            return this.editableRealm
            && this.realmMap[this.editableRealm.id]
            && this.editableRealm.androidApplicationId !== this.realmMap[this.editableRealm.id].androidApplicationId;
        },
        compactAuthorizationTokenLabels() {
            return this.authorizationTokenList.map((item) => compact(item.label || ''));
        },
        compactRealmLabels() {
            return this.realmList.map((item) => compact(item.label || ''));
        },
        compactRealmDomains() {
            return this.realmList.map((item) => compact(item.domain || ''));
        },
        compactRealmAndroidApplicationIds() {
            return this.realmList.map((item) => compact(item.androidApplicationId || ''));
        },
        authorizationTokenList() {
            const list = Object.values(this.authorizationTokenMap);
            list.sort((a, b) => (`${a.label}`).localeCompare(b.label));
            console.log('list', list);
            return list;
        },
        realmList() {
            const list = Object.values(this.realmMap);
            list.sort((a, b) => (`${a.label}`).localeCompare(b.label));
            console.log('list', list);
            return list;
        },
        ACCOUNT_TYPE_ENTERPRISE() {
            return ACCOUNT_TYPE_ENTERPRISE;
        },
        ACCOUNT_TYPE_PERSONAL() {
            return ACCOUNT_TYPE_PERSONAL;
        },
        ACCOUNT_TYPE_FREE() {
            return ACCOUNT_TYPE_FREE;
        },
    },
    watch: {
        dialogAuthorizationToken(val) {
            if (!val) {
                this.closeDialogAuthorizationToken();
            }
        },
        dialogNewAuthorizationToken(val) {
            if (!val) {
                this.closeDialogNewAuthorizationToken();
            }
        },
        dialogRealm(val) {
            if (!val) {
                this.closeDialogRealm();
            }
        },
        dialogNewRealm(val) {
            if (!val) {
                this.closeDialogNewRealm();
            }
        },
        dialogDeleteRealm(val) {
            if (!val) {
                this.closeDialogDeleteRealm();
            }
        },
    },
    methods: {
        async init() {
            // load concurrently:
            if (this.currentAccount.type === ACCOUNT_TYPE_ENTERPRISE) {
                await Promise.all([
                    this.fetchAuthorizationTokenList(),
                    this.fetchRealmList(),
                ]);
            }
        },
        async fetchAuthorizationTokenList() {
            try {
                const authorizationTokenResponse = await loginshield.account.getAuthorizationTokenList({ accountId: this.currentAccount.id });
                if (authorizationTokenResponse && authorizationTokenResponse.data) {
                    const map = {};
                    authorizationTokenResponse.data.forEach((item) => { map[item.id] = item; });
                    this.authorizationTokenMap = map;
                }
            } catch (error) {
                console.log('fetchAuthorizationTokenList failed', error);
            }
        },
        async fetchRealmList() {
            try {
                const realmListResponse = await loginshield.account.getRealmList({ accountId: this.currentAccount.id });
                if (realmListResponse && realmListResponse.data) {
                    const map = {};
                    realmListResponse.data.forEach((item) => {
                        const itemCopy = { ...item };
                        if (itemCopy.android && typeof itemCopy.android === 'object' && typeof itemCopy.android.allowLoginFrom === 'object' && itemCopy.android.allowLoginFrom.length > 0) {
                            itemCopy.androidApplicationId = itemCopy.android.allowLoginFrom[0].applicationId;
                        } else {
                            itemCopy.androidApplicationId = null;
                        }
                        map[item.id] = itemCopy;
                    });
                    this.realmMap = map;
                    console.log('init realmMap: ', this.realmMap);
                }
            } catch (error) {
                console.log('fetchRealmList failed', error);
            }
        },
        validateformNewRealm() {
            if (this.$refs.formNewRealmRef.validate()) {
                this.createRealm();
            }
        },
        validateFormEditRealmLabel() {
            if (this.$refs.formRealmLabelRef.validate()) {
                this.editRealmLabel();
            }
        },
        validateFormEditRealmDomain() {
            if (this.$refs.formRealmDomainRef.validate()) {
                this.editRealmDomain();
            }
        },
        validateFormEditRealmAndroidApplicationId() {
            if (this.$refs.formRealmAndroidApplicationIdRef.validate()) {
                this.editAndroidApplicationId();
            }
        },
        validateFormEditRealmImage() {
            if (this.$refs.formRealmImageRef.validate()) {
                this.editRealmImage();
            }
        },
        validateformNewAuthorizationToken() {
            if (this.$refs.formNewAuthorizationTokenRef.validate()) {
                this.createAuthorizationToken();
            }
        },
        validateFormEditAuthorizationToken() {
            if (this.$refs.formAuthorizationTokenRef.validate()) {
                this.editAuthorizationToken();
            }
        },
        async editRealmImage() {
            console.log('editRealmImage', this.realmImage);
            if (this.realmImage.size > 0) {
                const result = await this.$store.dispatch('editRealmImage', { id: this.editableRealm.id, fileInput: this.realmImage });
                if (result) {
                    this.snackbarRealmImageUpdated = true;
                    this.fetchRealmList();
                } else {
                    this.snackbarRealmImageUpdateFailed = true;
                }
                this.closeDialogRealm();
            }
        },
        openDialogAuthorizationToken(tokenId) {
            this.dialogAuthorizationToken = true;
            this.editableAuthorizationToken = { ...this.authorizationTokenMap[tokenId], id: tokenId };
            const resetForm = function resetForm() {
                this.$refs.formAuthorizationTokenRef.resetValidation();
            };
            console.log('editableAuthorizationToken: ', this.editableAuthorizationToken);
            console.log('authorizationTokenMap[tokenId]: ', this.authorizationTokenMap[tokenId]);
            console.log('tokenId: ', tokenId);
            setTimeout(resetForm.bind(this), 1);
            setTimeout(() => {
                this.$refs.authorizationTokenLabelRef.focus();
            }, 200);
        },
        openDialogRealm(id) {
            this.dialogRealm = true;
            this.editableRealm = { ...this.realmMap[id], id };
            setTimeout(() => {
                this.$refs.formRealmLabelRef.resetValidation();
                this.$refs.formRealmDomainRef.resetValidation();
                this.$refs.formRealmAndroidApplicationIdRef.resetValidation();
                this.$refs.formRealmImageRef.resetValidation();
                this.$refs.realmLabelRef.focus();
            }, 1);
        },
        openDialogNewAuthorizationToken() {
            let label;
            let i = 0;
            do {
                i += 1;
                label = `Untitled ${i}`;
            } while (this.compactAuthorizationTokenLabels.includes(compact(label)));

            this.editableAuthorizationToken.label = label;
            this.dialogNewAuthorizationToken = true;
            setTimeout(() => {
                this.$refs.editableAuthorizationTokenLabelRef.focus();
            }, 200);
        },
        openDialogNewRealm() {
            let label;
            let i = 0;
            do {
                i += 1;
                label = `Untitled ${i}`;
            } while (this.compactRealmLabels.includes(compact(label)));
            this.editableRealm.label = label;
            this.editableRealm.domain = '';
            this.dialogNewRealm = true;
            setTimeout(() => {
                this.$refs.newRealmDomainRef.resetValidation();
                this.$refs.newRealmLabelRef.focus();
            }, 1);
        },
        openDialogDeleteRealm() {
            this.dialogDeleteRealm = true;
        },
        closeDialogAuthorizationToken() {
            this.errorDeletingAuthorizationToken = false;
            this.dialogAuthorizationToken = false;
            // this.editableAuthorizationToken = { id: null, label: null, token: null };
            this.editableAuthorizationToken.id = null;
            this.editableAuthorizationToken.label = null;
        },
        closeDialogRealm() {
            this.errorDeletingRealm = false;
            this.dialogRealm = false;
            this.editableRealm = {
                id: null, label: null, domain: null, androidApplicationId: null,
            };
        },
        closeDialogNewAuthorizationToken() {
            this.errorCreateAuthorizationToken = false;
            this.errorEditAuthorizationToken = false;
            this.authorizationTokenCreated = false;
            this.showAuthorizationToken = false;
            this.dialogNewAuthorizationToken = false;
            // this.editableAuthorizationToken = { id: null, label: null, token: null };
            this.editableAuthorizationToken.id = null;
            this.editableAuthorizationToken.label = null;
            this.authorizationToken = null;
        },
        closeDialogNewRealm() {
            this.errorCreateRealm = false;
            this.editableRealm = {
                id: null, label: null, domain: null, androidApplicationId: null,
            };
            this.Realm = null;
            // this.$refs.newRealmLabelRef.reset();
            // this.$refs.newRealmDomainRef.reset();
            this.dialogNewRealm = false;
        },
        closeDialogDeleteRealm() {
            this.errorDeletingRealm = false;
            this.dialogDeleteRealm = false;
        },
        async createAuthorizationToken() {
            console.log('editableAuthorizationToken: ', this.editableAuthorizationToken);
            const response = await loginshield.account.createAuthorizationToken({ accountId: this.currentAccount.id, label: this.editableAuthorizationToken.label });
            console.log('response: ', response);
            if (response.id) {
                this.authorizationToken = response.token;
                this.showAuthorizationToken = true;
                const tokenInfo = {
                    id: response.id, label: this.editableAuthorizationToken.label, /* response.label, */
                };
                this.authorizationTokenMap = { ...this.authorizationTokenMap, [response.id]: tokenInfo };
                this.authorizationTokenCreated = true;
                // this.editableAuthorizationToken = { ...tokenInfo };
                this.editableAuthorizationToken.id = tokenInfo.id;
                // this.editableAuthorizationToken.label = tokenInfo.label; // already set before this request
                this.formNewAuthorizationToken = false;
                this.snackbarAuthorizationTokenCreated = true;
                setTimeout(() => {
                    this.$refs.tokenValueRef.focus();
                }, 200);
            } else {
                this.errorCreateAuthorizationToken = true;
            }
        },
        async createRealm() {
            console.log('createRealm editableRealm: ', this.editableRealm);
            const response = await loginshield.account.createRealm({ accountId: this.currentAccount.id, label: this.editableRealm.label, domain: this.editableRealm.domain });
            console.log('response: ', response);
            if (response.id) {
                const realmInfo = {
                    id: response.id, label: response.label, domain: response.domain,
                };
                this.realmMap = { ...this.realmMap, [response.id]: realmInfo };
                this.dialogNewRealm = false;
                this.snackbarRealmCreated = true;
                console.log('realmMap: ', this.realmMap);
                console.log('realmMap[id]', this.realmMap[response.id]);
            } else {
                this.errorCreateRealm = true;
            }
        },
        async editAuthorizationToken() {
            const response = await loginshield.account.editAuthorizationToken({ id: this.editableAuthorizationToken.id, label: this.editableAuthorizationToken.label });
            if (response.isEdited) {
                this.snackbarAuthorizationTokenEdited = true;
                const tokenInfo = { id: this.editableAuthorizationToken.id, label: this.editableAuthorizationToken.label };
                this.authorizationTokenMap = { ...this.authorizationTokenMap, [tokenInfo.id]: tokenInfo };
                this.closeDialogAuthorizationToken();
                this.closeDialogNewAuthorizationToken();
            } else {
                this.errorEditAuthorizationToken = true;
            }
        },
        async editRealmLabel() {
            const response = await loginshield.account.editRealm({
                id: this.editableRealm.id, label: this.editableRealm.label,
            });
            console.log('editRealmLabel response: ', response);
            if (response.isEdited) {
                this.snackbarRealmEdited = true;
                const realmInfo = {
                    ...this.realmMap[this.editableRealm.id], label: this.editableRealm.label,
                };
                this.realmMap = { ...this.realmMap, [realmInfo.id]: realmInfo };
                this.closeDialogRealm();
            } else {
                this.errorCreateRealm = true;
            }
        },
        async editRealmDomain() {
            const response = await loginshield.account.editRealm({
                id: this.editableRealm.id, domain: this.editableRealm.domain,
            });
            console.log('editRealmDomain response: ', response);
            if (response.isEdited) {
                this.snackbarRealmEdited = true;
                const realmInfo = {
                    ...this.realmMap[this.editableRealm.id], domain: this.editableRealm.domain,
                };
                this.realmMap = { ...this.realmMap, [realmInfo.id]: realmInfo };
                this.closeDialogRealm();
            } else {
                this.errorCreateRealm = true;
            }
        },
        async editAndroidApplicationId() {
            console.log('applicationId', this.editableRealm.androidApplicationId);
            const response = await loginshield.account.editRealm({
                id: this.editableRealm.id, androidApplicationId: this.editableRealm.androidApplicationId,
            });
            console.log('editAndroidApplicationId response: ', response);
            if (response.isEdited) {
                this.snackbarRealmEdited = true;
                const realmInfo = {
                    ...this.realmMap[this.editableRealm.id], androidApplicationId: this.editableRealm.androidApplicationId,
                };
                this.realmMap = { ...this.realmMap, [realmInfo.id]: realmInfo };
                this.closeDialogRealm();
            } else {
                this.errorCreateRealm = true;
            }
        },
        async deleteAuthorizationToken() {
            const response = await loginshield.account.deleteAuthorizationToken(this.editableAuthorizationToken.id);
            if (response.isDeleted) {
                const map = { ...this.authorizationTokenMap };
                delete map[this.editableAuthorizationToken.id];
                this.authorizationTokenMap = map;
                this.closeDialogAuthorizationToken();
                this.snackbarAuthorizationTokenDeleted = true;
            }
            this.errorDeletingAuthorizationToken = true;
        },
        async deleteRealm() {
            const response = await loginshield.account.deleteRealm(this.editableRealm.id);
            if (response.isDeleted) {
                const map = { ...this.realmMap };
                delete map[this.editableRealm.id];
                this.realmMap = map;
                this.closeDialogDeleteRealm();
                this.closeDialogRealm();
                this.snackbarRealmDeleted = true;
            }
            this.errorDeletingRealm = true;
        },
        copyAuthorizationToken() {
            const input = this.$refs.tokenValueRef.$el.querySelector('textarea');
            input.focus();
            input.select();
            input.setSelectionRange(0, input.value.length);
            document.execCommand('copy');
            this.snackbarAuthorizationTokenCopied = true;
        },
        copyRealmId() {
            const input = this.$refs.realmIdRef.$el.querySelector('input');
            input.focus();
            input.select();
            input.setSelectionRange(0, input.value.length);
            document.execCommand('copy');
            this.snackbarRealmIdCopied = true;
        },
        getRealmIcon(imageBase64) {
            console.log('getRealmIcon');
            if (imageBase64) {
                return `data:image/png;base64,${imageBase64}`;
            }
            if (this.currentAccount.icon) {
                return `data:image/png;base64,${this.currentAccount.icon}`;
            }
            return '';
        },
    },
    mounted() {
        console.log('mounted');
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
